<template>
  <div class="app-mall-cart container">
    <div class="content ex padding-20-0" v-loading="loading">
      <el-steps :active="0" process-status="success" simple>
        <el-step title="我的购物车" />
        <el-step title="填写核对订单信息" />
        <el-step title="成功提交订单" />
      </el-steps>
      <template v-if="store && ((store.goods && store.goods.length) || (store.groups && store.groups.length))">
        <template v-if="store.goods && store.goods.length">
          <div class="fc-g padding-10-0 h c sb">
            采购商品
            <retail-order :supplier="supplier" :exclude-keys="retailExcludeKeys" @submit="handleGoodsAdd" />
          </div>
          <el-table :data="store.goods">
            <el-table-column width="40">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row._checked" :disabled="!scope.row._checkable" @change="handleUpdateCheck('goods', scope.row)" />
              </template>
            </el-table-column>
            <el-table-column width="100">
              <template slot-scope="scope">
                <div class="cart-thumbnail" :style="{backgroundImage: `url(${scope.row.coverImg || '/static/img/no-image.png'})`}"></div>
              </template>
            </el-table-column>
            <el-table-column prop="code" label="商品编码" width="150" />
            <el-table-column prop="erpCode" label="ERP编码" width="150" />
            <el-table-column label="商品" min-width="240">
              <div style="line-height: 1.5" slot-scope="scope">
                <div>{{scope.row.goodsName}}</div>
                <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
              </div>
            </el-table-column>
            <el-table-column label="单价" width="100" align="right">
              <template slot-scope="scope">
                <div v-if="scope.row._price.loading" class="fc-g">正在获取中…</div>
                <div v-else-if="scope.row._price.error" class="h c">
                  <span class="fc-e">{{scope.row._price.error}}</span>
                  <a href="javascript:void(0)" @click="loadGoodsPrice(scope.row)">
                    &nbsp;
                    <i class="el-icon-refresh"></i>
                  </a>
                </div>
                <div style="line-height: 1.5" class="v e" v-else>
                  <b class="fc-e">{{$price(scope.row._price.value)}}</b>
                  <span class="fc-g through" v-if="scope.row._price.oldValue > scope.row._price.value">{{$price(scope.row._price.oldValue)}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="数量" width="110" align="center">
              <template slot-scope="scope">
                <el-input-number v-model="scope.row.count" :min="scope.row.minSalesQuantity || 0.01" :max="9999" :step="1" :precision="2" size="mini" controls-position="right" @change="$checkNaN(scope.row, 'count', scope.row.minSalesQuantity || 0.01, handleGoodsCountChange)" style="width: 100%;" />
              </template>
            </el-table-column>
            <el-table-column width="40">
              <div class="row-commands" slot-scope="scope">
                <el-button type="text" size="mini" class="danger" icon="el-icon-delete" @click="handleRemove('goods', scope.row)" />
              </div>
            </el-table-column>
          </el-table>
        </template>

        <template v-if="store.groups && store.groups.length">
          <div class="fc-g padding-10-0">组合销售</div>
          <el-table :data="store.groups" :span-method="calcGroupSaleSpan">
            <el-table-column width="40">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row._checked" @change="handleUpdateCheck('group', scope.row)" />
              </template>
            </el-table-column>
            <el-table-column width="100">
              <template slot-scope="scope">
                <div class="cart-thumbnail" :style="{backgroundImage: `url(${scope.row.coverImg || '/static/img/no-image.png'})`}"></div>
              </template>
            </el-table-column>
            <el-table-column label="商品编码" width="150" class-name="el-table--inner-column transparent">
              <template slot-scope="scope">
                <div class="h c" style="border-bottom: #ececec solid 1px;">
                  <div class="flex padding-0-10">
                    <b>{{scope.row.groupSaleName}}</b>
                  </div>
                  <div style="width: 110px;" class="padding-10">
                    <el-input-number size="mini" v-model="scope.row.times" :min="scope.row.minTimes" :max="scope.row.maxTimes || 9999" :step="1" :precision="0" controls-position="right" @change="$checkNaN(scope.row, 'times', scope.row.minTimes, handleGroupSaleCountChange)" style="width: 100%;" />
                  </div>
                </div>
                <el-table :data="scope.row.items" :show-header="false">
                  <el-table-column prop="code" label="商品编码" width="150" />
                  <el-table-column prop="erpCode" label="ERP编码" width="150" />
                  <el-table-column label="商品" min-width="240">
                    <div style="line-height: 1.5" slot-scope="subScope">
                      <div>{{subScope.row.goodsName}}</div>
                      <div class="fc-g">{{$goodsSpecConvert(subScope.row.goodsSpec)}}</div>
                    </div>
                  </el-table-column>
                  <el-table-column label="单价" width="100" align="right">
                    <template slot-scope="subScope">
                      <div v-if="subScope.row._price.loading" class="fc-g">正在获取中…</div>
                      <div v-else-if="subScope.row._price.error" class="h c">
                        <span class="fc-e">{{subScope.row._price.error}}</span>
                        <a href="javascript:void(0)" @click="loadGoodsPrice(subScope.row)">
                          &nbsp;
                          <i class="el-icon-refresh"></i>
                        </a>
                      </div>
                      <div style="line-height: 1.5" class="v e" v-else>
                        <b class="fc-e">{{$price(subScope.row._price.value)}}</b>
                        <span class="fc-g through" v-if="subScope.row._price.oldValue > subScope.row._price.value">{{$price(subScope.row._price.oldValue)}}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="count" label="数量" width="110" align="center" />
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="ERP编码" width="150" />
            <el-table-column label="商品" min-width="240" />
            <el-table-column label="单价" width="100" align="right" />
            <el-table-column label="数量" width="110" align="center" />
            <el-table-column width="40">
              <div class="row-commands" slot-scope="scope">
                <el-button type="text" size="mini" class="danger" icon="el-icon-delete" @click="handleRemove('groups', scope.row)" />
              </div>
            </el-table-column>
          </el-table>
        </template>

        <div class="confirm-info">
          <div class="h c">
            <div class="padding-0-10 flex h c">
              <el-checkbox v-model="selectAll" @change="handleSelectAllChange">全选</el-checkbox>
              <el-divider direction="vertical" />
              <el-button type="text" :disabled="!counts._nums" @click="handleCartClear(false)">移除所选商品</el-button>
              <el-divider direction="vertical" />
              <el-button type="text" @click="handleCartClear(true)">清空购物车</el-button>
            </div>
            <div class="fc-g padding-10 h c">
              已经选择&nbsp;
              <b class="fc-e">{{counts._nums}}</b>&nbsp;件商品
              <el-divider direction="vertical" />总价:
              <b class="fc-e fs-large">￥{{(counts._price / 100).toFixed(2)}}</b>
            </div>
            <el-button class="no-radius" size="large" type="danger" :disabled="!counts._nums" @click="handleSubmit">
              <b class="fs-huge">去结算</b>
            </el-button>
          </div>
        </div>
      </template>
      <template v-else-if="!loading">
        <div class="v c padding-20">
          <div class="padding-20">目前购物车还没有任何商品</div>
          <div class="h">
            <el-button type="primary" @click="handleList">前去逛逛</el-button>
            <retail-order :supplier="supplier" :exclude-keys="retailExcludeKeys" @submit="handleGoodsAdd" style="margin-left: 10px;" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getPurchaseGoodsPrice } from "@/api/purchaseOrder";
import retailOrder from "../../purchase/order/retail-order";
import request from "@/utils/request";

export default {
  components: { retailOrder },
  props: {
    supplier: Number | String,
  },
  watch: {
    supplier: "loadCart",
  },
  data() {
    return {
      loading: false,
      selectAll: false,
      buyEnable: false,
      store: {},
    };
  },
  computed: {
    counts() {
      let _nums = 0,
        _price = 0,
        count = 0,
        ss = 0,
        gs = 0;
      this.store.goods.forEach((g) => {
        if (g._checkable) {
          ss++;
          if (g._checked) {
            count++;
            _nums += g.count;
            _price += (g._price.value || 0) * g.count;
          }
        }
      });
      this.store.groups.forEach((o) => {
        if (o._checked) {
          count++;
          gs++;
          o.items.forEach((g) => {
            _nums += g.count;
            _price += (g._price.value || 0) * g.count;
          });
        }
      });
      this.selectAll = count === ss + gs;
      return {
        _nums: _nums,
        _price: _price,
      };
    },
    retailExcludeKeys() {
      let keys = [];
      if (this.store && this.store.goods && this.store.goods) {
        this.store.goods.forEach((o) => {
          if (o.source && o.source.length) {
            o.source.forEach((fid) => {
              keys.push(fid + "_" + o.goodsId);
            });
          }
        });
      }
      return keys;
    },
  },
  methods: {
    handleGoodsAdd(list) {
      (list || []).forEach((o) => {
        window.mall.cart.add("goods", {
          _id: this.$uuid(),
          goodsId: o.id,
          goodsSpec: o.specs,
          goodsName: o.name,
          minSalesQuantity: o.minSalesQuantity || 0,
          coverImg: o.coverImg,
          code: o.code,
          erpCode: o.erpCode,
          source: o.source || [],
          groupId: this.$uuid(),
          shipDate: this.$now.getDiffDays(o.deliveryCycle || 7),
          count: o.count || o.minSalesQuantity || 1,
          info: "",
          discountAmount: 0,
          giftDiscountPrice: 0,
          giftDiscountAmount: 0,
          promotionId: null,
          promotionJoinType: "",
          promotionMutex: false,
          wholePromotionId: null,
          wholePromotionName: null,
          wholeGroupId: null,
          singleGoodsGroupId: null,
          singleGoodsLadderId: null,
          groupSaleId: null,
          groupSaleItemGoodsId: null,
          groupSaleName: null,
          groupSaleRule: null,
        });
      });
      this.loadCart();
    },
    calcGroupSaleSpan({ rowIndex, columnIndex }) {
      if (columnIndex === 2) return { rowspan: 1, colspan: 5 };
      else if (columnIndex > 2 && columnIndex < 7)
        return { rowspan: 0, colspan: 0 };
    },
    loadCart() {
      let s = JSON.parse(JSON.stringify(window.mall.cart.get()));
      if (s) {
        (s.goods || []).forEach((g) => {
          g._price = {
            loading: false,
            error: false,
            oldValue: 0,
            value: 0,
          };
          g._checkable = !!(g.source && g.source.length) || !(this.buyEnable === false && g.purchaseLimit);
          if (!g._checkable) {
            g._checked = false;
          }
          this.loadGoodsPrice(g);
        });
        (s.groups || []).forEach((g) => {
          (g.items || []).forEach((o) => {
            o._price = {
              loading: false,
              error: false,
              oldValue: 0,
              value: 0,
            };
            o._checkable = true;
            this.loadGoodsPrice(o);
          });
        });
        this.store = s;
      }
    },

    handleGoodsCountChange(g) {
      window.mall.cart.updateCount("goods", g);
      this.loadGoodsPrice(g);
    },

    handleGroupSaleCountChange(o) {
      window.mall.cart.updateCount("group", o);
      o.items.forEach((g) => {
        g.count = g.groupSaleRule.quantity * o.times;
        this.loadGoodsPrice(g);
      });
    },

    handleUpdateCheck(type, o) {
      window.mall.cart.updateCheck(type, o);
    },

    loadGoodsPrice(g) {
      if (g && g.goodsId && !isNaN(g.count)) {
        g._price.loading = true;
        g._price.error = false;
        g._price.oldValue = null;
        g._price.value = null;
        getPurchaseGoodsPrice({
          goodsId: g.goodsId,
          quantity: g.count,
          sellerId: this.supplier,
        })
          .then((res) => {
            let v = res.goodsPrice;
            if (typeof v !== "number") {
              g._price.error = "没有价格";
            } else if (g.promotionId) {
              if (g.promotionJoinType === "groupSale" && g.groupSaleRule) {
                if (g.groupSaleRule.method === "price") {
                  v = g.groupSaleRule.price;
                } else if (g.groupSaleRule.method === "save") {
                  v -= g.groupSaleRule.saveAmount;
                } else if (g.groupSaleRule.method === "discount") {
                  v = Math.round(v * g.groupSaleRule.discount);
                }
              }
            }
            g._price.oldValue = res.goodsPrice;
            g._price.value = v < 0 ? 0 : v;
            g._price.loading = false;
          })
          .catch((err) => {
            g._price.error = "获取失败";
            g._price.loading = false;
          });
      }
    },

    loadBuyLimit() {
      if (this.supplier) {
        request({
          url: "api/buyLimit",
          method: "get",
          params: {
            sellerId: this.supplier,
          },
        }).then((res) => {
          // this.buyEnable = false;
          this.buyEnable = res.allow;
          this.loadCart();
        });
      }
    },

    handleTimesChange(o, g) {
      if (typeof o.times !== "number") {
        this.$nextTick((_) => {
          o.times = g ? g.minSalesQuantity : 1;
          if (g) this.loadGoodsPrice(o, g);
          else
            o.items.forEach((so) => {
              this.loadGoodsPrice(o, so);
            });
        });
      } else {
        if (g) this.loadGoodsPrice(o, g);
        else
          o.items.forEach((so) => {
            this.loadGoodsPrice(o, so);
          });
      }
    },
    encode(val) {
      return btoa(val);
    },
    handleRemove(type, item) {
      window.mall.cart.remove(type, item);
      this.loadCart();
    },
    handleCartClear(clearAll = true) {
      this.$confirm(
        clearAll ? "您确定要清空购物车吗？" : "您确定要移除勾选的商品吗？",
        "操作确认",
        {
          type: "warning",
        }
      ).then((_) => {
        window.mall.cart.clear(clearAll);
        this.loadCart();
      });
    },
    handleList() {
      this.$router.push({
        path: "/mall/list",
      });
    },
    handleSelectAllChange(checked) {
      if (this.store) {
        this.store.goods.forEach((o) => {
          if (o._checkable) o._checked = checked;
        });
        this.store.groups.forEach((o) => {
          o._checked = checked;
        });
        window.mall.cart.updateCheck(checked, this.buyEnable);
      }
    },
    handleSubmit() {
      let o = {
        goods: this.store.goods.filter((g) => {
          return g._checkable && g._checked;
        }),
        groups: this.store.groups.filter((g) => {
          return g._checked;
        }),
      };
      if ((o.goods && o.goods.length) || (o.groups && o.groups.length)) {
        window.localStorage.setItem("cart-confirm", JSON.stringify(o));
        this.$router.push({
          path: "/mall/cart/confirm",
        });
      }
    },
  },
  deactivated() {
    window.mall.cart.save();
  },
  mounted() {
    this.loadBuyLimit();
  },
  activated() {
    this.loadBuyLimit();
  },
};
</script>